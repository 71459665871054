import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import MicroModal from 'micromodal';

// Utils
import { cn } from 'utils';
import getYoutubeVideoId from '../../../src/utils/getYoutubeVideoId';

// Styles
import styles from './HeroSectionBlock.module.css';

// Components
import YoutubePlayer from '../../atoms/YoutubePlayer/YoutubePlayer';
import Cursor from '../../atoms/Cursor/Cursor';
import useCursor from '../../atoms/Cursor/useCursor';

const Modal = dynamic(() => import('../Modal/Modal'), {
  ssr: false,
});

function HeroSectionBlock({
  title, media, youtube,
}) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [youtubeIsDisplay, setYoutubeIsDisplay] = useState(false);
  /**
   * Handle Modal Youtube
   * @returns {void}
   */
  const handleModalYoutube = () => {
    if (!youtube) return;
    MicroModal.show('HeroYoutubeVideo', {
      onShow: () => {
        setYoutubeIsDisplay(true);
      },
      onClose: () => {
        setTimeout(() => {
          setYoutubeIsDisplay(false);
        }, 300);
      },
    });
  };

  const {
    textEnter, textLeave, variants, cursorVariant,
  } = useCursor(ref, handleModalYoutube);

  return (
    <>
      <div
        className={styles.container}
      >
        <div
          className={styles.heroContainer}
        >
          <div className={cn([styles.heroImg, styles.isMobile])}>
            {media?.url ? (
              <Image
                priority
                src={media.url}
                alt={media?.description}
                fill
                sizes="100vw"
              />
            ) : null}
            <div className={styles.backdrop} />
            <div className={styles.heroText}>{title}</div>
          </div>
          <div
            className={cn([styles.heroImg, styles.isDesktop, youtube ? styles.hasYoutube : ''])}
            ref={ref}
          >
            {media?.url ? (
              <Image
                priority
                src={media.url}
                alt={media?.description}
                fill
                onMouseEnter={textEnter}
                onMouseLeave={textLeave}
                sizes="(max-width: 768px) 100vw,
                                           50vw"
              />
            ) : null}
            {youtube
              ? (
                <Cursor
                  variants={variants}
                  cursorVariant={cursorVariant}
                  label={t('heroSectionBlock.watchVideo')}
                  onClick={handleModalYoutube}
                />
              )
              : null}
            <div className={styles.backdrop} />
            <div className={styles.heroText}>{title}</div>
          </div>
        </div>
      </div>
      {youtube ? (
        <Modal
          id="HeroYoutubeVideo"
          className={styles.modal}
          hideCancelButton
          maxWidth={1000}
        >
          {youtubeIsDisplay ? (
            <YoutubePlayer videoId={getYoutubeVideoId(youtube)} />
          ) : null}
        </Modal>
      ) : null}
    </>
  );
}

HeroSectionBlock.propTypes = {
  title: PropTypes.string,
  media: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  youtube: PropTypes.string,
};

HeroSectionBlock.defaultProps = {
  title: '',
  media: {
    url: '',
    description: '',
  },
  youtube: '',
};

export default HeroSectionBlock;
