import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

const STATE_VARIANT = {
  DEFAULT: 'default',
  TEXT: 'text',
};

function useCursor(ref, onClick) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariant, setCursorVariant] = useState(STATE_VARIANT.DEFAULT);

  const mouseMove = useCallback((e) => {
    setMousePosition({
      x: e.clientX,
      y: e.clientY,
    });
  }, []);

  const variants = useMemo(() => ({
    default: {
      opacity: 0,
      x: mousePosition.x,
      y: mousePosition.y,
      rotate: 180,
      scale: 0.1,
      transition: {
        scale: { duration: 0.2 },
        opacity: { duration: 0.2 },
        default: { duration: 0 },
        rotate: { duration: 0 },
      },
    },
    text: {
      x: mousePosition.x,
      y: mousePosition.y,
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        scale: { duration: 0.15 },
        rotate: { duration: 0.4 },
        opacity: { duration: 0.0 },
        default: { duration: 0 },
      },
    },
  }), [mousePosition]);

  // Handle cursor variant
  const textEnter = useCallback(() => setCursorVariant(STATE_VARIANT.TEXT), []);
  const textLeave = useCallback(() => setCursorVariant(STATE_VARIANT.DEFAULT), []);

  // Handle cursor position
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mousemove', mouseMove);
      // add button click event
      ref.current.addEventListener('click', onClick);
    }
    return () => {
      ref?.current?.removeEventListener('pointermove', mouseMove);
      ref?.current?.removeEventListener('click', onClick);
    };
  }, []);

  return {
    textEnter,
    textLeave,
    variants,
    cursorVariant,
  };
}

useCursor.propTypes = {
  ref: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default useCursor;
